<template>
  <div>
    <div class="title">
      <el-input v-model="title" placeholder="请输入标题"></el-input>
    </div>
    <div class="input-textarea">
      <el-input
        type="textarea"
        :autosize="{ minRows: 35 }"
        v-model="content"
        placeholder="请输入模板内容"
      ></el-input>
    </div>
    <div class="button-list">
      <el-button type="" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleConfirm">确认</el-button>
    </div>
  </div>
</template>

<script>
import { addMessageModule, editMessageModule } from '@/api/classify'
export default {
  props: ['current', 'show', 'categoryId'],
  data () {
    return {
      content: this.current
        ? JSON.parse(JSON.stringify(this.current.content))
        : '',
      title: this.currnet ? JSON.parse(JSON.stringify(this.current.title)) : ''
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          this.content = this.current
            ? JSON.parse(JSON.stringify(this.current.content))
            : ''
          this.title = this.current
            ? JSON.parse(JSON.stringify(this.current.title))
            : ''
        }
      },
      immediate: true
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    handleConfirm () {
      if (this.current) {
        const { id } = this.current
        editMessageModule(id, { content: this.content, title: this.title })
          .then(() => {
            this.$message({
              showClose: true,
              message: '修改成功!',
              type: 'success'
            })
            this.$emit('refreshData')
          })
          .finally(() => {
            this.handleClose()
          })
      } else {
        addMessageModule({
          categoryId: this.categoryId,
          content: this.content,
          title: this.title
        })
          .then(() => {
            this.$message({
              showClose: true,
              message: '添加成功!',
              type: 'success'
            })
            this.$emit('refreshData')
          })
          .finally(() => {
            this.handleClose()
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.input-textarea {
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
}
.title {
  box-sizing: border-box;
  padding: 20px;
}
.button-list {
  text-align: center;
}
</style>
