<template>
  <div class="classify-templateManage">
    <div class="flex-between">
      <div style="display: flex">
        <el-button @click="getList" size="small">刷新数据</el-button>
        <el-input
          placeholder="输入标题或者模板内容"
          v-model="search"
          class="input-with-select"
          @keydown.enter.native="handlePageChange(1)"
          clearable
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handlePageChange(1)"
          ></el-button>
        </el-input>
      </div>
      <el-button icon="el-icon-plus" @click="handleAdd(null)" type="primary"
        >新增模板</el-button
      >
    </div>
    <el-table
      :loading="loading"
      :data="dataList"
      height="calc(100vh - 380px)"
      style="width: 100%; margin: 20px 0"
    >
      <el-table-column prop="id" label="ID" width="100"> </el-table-column>
      <el-table-column prop="title" label="标题" width="100"></el-table-column>
      <el-table-column prop="content" label="模板内容"> </el-table-column>
      <el-table-column label="操作" width="150px">
        <template slot-scope="scope">
          <el-button class='button-small'  type="primary" @click="handleAdd(scope.row)">修改</el-button>
          <el-button class='button-small'  type="danger" @click="handleDelete(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 20px; text-align: right"
      background
      @current-change="handlePageChange"
      :current-page="page"
      :page-size="15"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-drawer
      size="700px"
      :title="`${isEdit ? '编辑模板' : '新增模板'}`"
      :append-to-body="true"
      :visible.sync="isShowTemplate"
    >
      <custom-template
        :current="current"
        :categoryId="categoryId"
        :show="isShowTemplate"
        @close="isShowTemplate = false"
        @refreshData='getList'
      ></custom-template>
    </el-drawer>
  </div>
</template>

<script>
import {
  deleteMessageModule,
  getMessageModule
} from '@/api/classify'
import customTemplate from './customTemplate'

export default {
  name: 'Description',
  props: {
    categoryId: Number
  },
  data () {
    return {
      loading: false,
      search: null,
      page: 1,
      total: 0,
      dataList: [],
      isShowTemplate: false,
      isEdit: false,
      current: null
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.getList())
  },
  watch: {
    categoryId (newVal) {
      if (newVal) {
        this.search = null
        this.handlePageChange(1)
      }
    }
  },
  methods: {
    handlePageChange (page) {
      this.page = page
      this.getList()
    },
    getList () {
      if (!this.categoryId) {
        return false
      }
      this.loading = true
      getMessageModule({
        page: this.page,
        pageSize: 15,
        search: this.search,
        categoryId: this.categoryId
      })
        .then(res => {
          this.dataList = res.list
          this.total = res.total
        })
        .finally(() => (this.loading = false))
    },
    handleAdd (item) {
      this.current = item
      this.isEdit = !!this.current
      this.isShowTemplate = true
    },
    handleDelete (id) {
      this.$confirm('此操作将永久删除该描述， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteMessageModule(id).then(() => {
            this.$message.success('删除成功')
            this.handlePageChange(1)
          })
        })
        .catch(() => {})
    }
  },
  components: {
    customTemplate
  }
}
</script>

<style lang="scss" scoped>
.input-with-select {
  margin-left: 20px;
}
/deep/ .el-drawer {
  overflow-y: auto;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
</style>
